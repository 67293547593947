import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { getUrls, productActions, uiActions } from "../../state";
import { Button, IconButton, Tooltip, Link } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DecrementIcon from "@material-ui/icons/Remove";
import RemoveIcon from "@material-ui/icons/Delete";
import HomeIcon from "@material-ui/icons/Home";
import SchoolIcon from "@material-ui/icons/LocationCity";
import InfoIcon from "@material-ui/icons/Info";
import BrushSharpIcon from "@material-ui/icons/BrushSharp";
import EditIcon from "@material-ui/icons/Edit";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { Link as RRLink } from "react-router-dom";
import { SwitchDeliveryWarningDialog } from "..";
import { ProductDetails } from "./ProductDetails";
import { productStructureTypes } from "../../lib";
import { productCardStyles } from "./ProductCard.styles";

function _ProductCard(props) {
  const {
    isSelected,
    quantity,
    product,
    selectedProducts,
    isShipToSchool,
    ship_to_school_label,
    homeUrl,
    product: {
      id,
      product_name,
      product_price,
      image,
      ship_to_school_only: STS,
      ship_to_home_only: STH,
      can_personalize,
      structure_type,
    },
    actions: { addProduct, removeProduct, changeQuantity },
  } = props;
  const dispatch = useDispatch();
  const isConfigurable = structure_type === productStructureTypes.CONFIGURABLE;
  const classes = productCardStyles({ isSelected });
  const [showDetails, setShowDetails] = useState(false);
  const [disable, setDisable] = useState(false);
  const [showDeliverySwitch, setShowDeliverySwitch] = useState(false);
  const [addedVariantCount, setAddedVariantCount] = useState(0);

  useEffect(() => {
    if ((isShipToSchool && STH) || (!isShipToSchool && STS)) {
      setDisable(true);
    } else setDisable(false);
  }, [isShipToSchool, STS, STH]);

  useEffect(() => {
    if (!isConfigurable) return;
    let ttlQty = 0;
    Object.keys(selectedProducts).forEach(pId => {
      const { qty, product: { product_parent_id } = {} } = selectedProducts[
        pId
      ];
      if (id === product_parent_id) ttlQty = ttlQty + qty;
    });
    setAddedVariantCount(ttlQty);
  }, [id, isConfigurable, selectedProducts]);

  const handleAddRemoveProduct = () => {
    if (disable) return;
    if (isSelected) {
      removeProduct(id);
    } else {
      if (isConfigurable) return setShowDetails(true);
      addProduct(id, product);
      if (can_personalize) setShowDetails(true);
    }
  };

  const handleQtyChange = (id, action) => {
    changeQuantity(id, action);
    if (can_personalize && action === "increment") setShowDetails(true);
  };

  const closeDeliverySwitchWarning = () => setShowDeliverySwitch(false);

  return (
    <div className={classes.card}>
      <div className={classes.imgWrapper}>
        <img
          className={classes.image}
          src={image}
          alt="product"
          onClick={() => setShowDetails(true)}
        />
        <div
          className={classes.moreDetails}
          onClick={() => setShowDetails(true)}
        >
          More details
        </div>
        {can_personalize && (
          <div className={classes.cardTag}>
            <BrushSharpIcon className={classes.cardTagIcon} /> Personalize it!
          </div>
        )}
      </div>

      <div className={classes.productName} onClick={() => setShowDetails(true)}>
        {product_name}
      </div>
      <div className={classes.price}>{`$${product_price}`}</div>

      {/* DISABLED */}
      {disable && (
        <div>
          <div className={classes.disableRow}>
            {STH ? (
              <HomeIcon color="primary" className={classes.shipIcon} />
            ) : (
              <SchoolIcon color="primary" className={classes.shipIcon} />
            )}

            <div className={classes.disableTxt}>
              Ship to {STH ? "home" : ship_to_school_label} only
            </div>
            <Tooltip
              title={`This item cannot be added to your cart because you selected shipping method "Ship to ${
                isShipToSchool ? ship_to_school_label : "home"
              }."`}
              placement="top"
              classes={{ tooltipPlacementTop: classes.tooltip }}
            >
              <InfoIcon color="primary" className={classes.infoIcon} />
            </Tooltip>
          </div>
          <div className={classes.changeDelivery}>
            <div>Change delivery to </div>
            <div
              className={classes.changeDeliveryLink}
              onClick={() => setShowDeliverySwitch(true)}
            >
              Ship to {STH ? "home" : ship_to_school_label}
            </div>
          </div>
        </div>
      )}

      {/* ADD */}
      {!isSelected && !disable && (
        <div className={classes.addButtonRow}>
          <Button
            variant="contained"
            color="primary"
            className={classes.addButton}
            onClick={handleAddRemoveProduct}
            startIcon={<AddIcon />}
          >
            Add
          </Button>

          {addedVariantCount > 0 && (
            <Link
              component={RRLink}
              to={`${homeUrl}#cart`}
              onClick={() => dispatch(uiActions.updateHashLinkTrigger())}
            >
              <div className={classes.variantsInCart}>
                <CheckCircleIcon className={classes.checkIcon} />
                <div className={classes.inCart}>
                  {addedVariantCount} in cart
                </div>
              </div>
            </Link>
          )}
        </div>
      )}

      {/* UPDATE */}
      {isSelected && (
        <div className={classes.addedActions}>
          <IconButton
            className={classes.iconButton}
            onClick={
              quantity > 1
                ? () => handleQtyChange(id, "decrement")
                : handleAddRemoveProduct
            }
          >
            {quantity > 1 && (
              <DecrementIcon className={classes.addedActionIcons} />
            )}
            {quantity < 2 && (
              <RemoveIcon
                className={classes.addedActionIcons}
                fontSize="small"
              />
            )}
          </IconButton>
          <div className={classes.addedNum}>{quantity} added</div>
          <IconButton
            className={classes.iconButton}
            onClick={() => handleQtyChange(id, "increment")}
          >
            <AddIcon className={classes.addedActionIcons} />
          </IconButton>
        </div>
      )}

      {isSelected && can_personalize && (
        <div
          className={classes.editPersonalizationWrapper}
          onClick={() => setShowDetails(true)}
        >
          <EditIcon className={classes.editIcon} />
          <span className={classes.editPersonalization}>Personalization</span>
        </div>
      )}

      <ProductDetails
        product={product}
        handleClose={setShowDetails}
        open={showDetails}
      />

      {showDeliverySwitch && (
        <SwitchDeliveryWarningDialog closeDialog={closeDeliverySwitchWarning} />
      )}
    </div>
  );
}

export const ProductCard = connect(
  state => {
    const { homeUrl } = getUrls(state);
    const {
      product: { selectedProducts, isShipToSchool },
      campaign: { ship_to_school_label },
    } = state;
    return { homeUrl, selectedProducts, isShipToSchool, ship_to_school_label };
  },
  dispatch => {
    return { actions: bindActionCreators(productActions, dispatch) };
  },
)(_ProductCard);
