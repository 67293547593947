import React, { Fragment, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  Button,
  CircularProgress,
  makeStyles,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton,
} from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import HomeIcon from "@material-ui/icons/Home";
import SchoolIcon from "@material-ui/icons/LocationCity";
import {
  formatMoney,
  CC_PAYMENT,
  shippingCalculatedByOptions,
  hexToRGBA,
  productTypes,
  convertGiftCardObjArrToCodeStrArr,
} from "../../lib";
import { productActions, setToast } from "../../state";
import { ApplePay } from "../donation/ApplePay";

function _OrderSummaryAndActions(props) {
  const {
    // parent props
    disableSubmit,
    submitting,
    form,
    formValues,
    validateFormAndOpenPayment,
    validateForm,

    // state props
    campaignId,
    isActive,
    canadianCampaign,
    forConfirmation,
    paymentType,
    disableRetry,
    ship_to_school_label,
    processing_fee_title,
    productInfo: {
      emptyCart,
      orderItemsTotal,
      orderOnlineFee,
      orderCustomFee,
      orderTotal,
      selectedProducts,
      orderItemCount,
      productDonation,
      productDonationProcessingFee,
      loadingOrderFees,
      shipping_calculated_by,
      orderFeesResolved,
      isShipToSchool,
      shipping,
      noShippingCharges,
      tax,
      giftCardsValue,
      custom_fee_label,
      rateQuotes,
      needsTaxFetch,
    },
  } = props;
  const classes = styles();
  const dispatch = useDispatch();
  const [needsPayment, setNeedsPayment] = useState(true);
  const [showFees, setShowFees] = useState(false);
  const [showShippingRates, setShowShippingRates] = useState(false);
  const [quoteId, setQuoteId] = useState(null);
  const [serviceName, setServiceName] = useState(null);
  const [displayingApplePay, setDisplayingApplePay] = useState(false);
  const [fetchingLiveRateTax, setFetchingLiveRateTax] = useState(false);

  useEffect(() => {
    if (
      shipping_calculated_by === shippingCalculatedByOptions.LIVE_RATES &&
      !noShippingCharges &&
      (loadingOrderFees || orderFeesResolved)
    ) {
      setShowShippingRates(true);
    } else setShowShippingRates(false);
  }, [
    shipping_calculated_by,
    noShippingCharges,
    loadingOrderFees,
    orderFeesResolved,
  ]);

  useEffect(() => {
    setNeedsPayment(Boolean(paymentType === CC_PAYMENT && orderTotal));
  }, [paymentType, orderTotal]);

  useEffect(() => {
    setShowFees(
      Boolean(forConfirmation || (!showShippingRates && orderFeesResolved)),
    );
  }, [showShippingRates, orderFeesResolved, forConfirmation]);

  const getFees = async () => {
    if (disableRetry) {
      return dispatch(setToast("Please refresh your page and start again."));
    }
    if (!validateForm(form)) return;
    dispatch(productActions.setCheckoutTriggered());
    setServiceName(null);
    setQuoteId(null);

    // TODO: if it's a canadianCampaign and there are giftCodes we need to fetch fees
    if (canadianCampaign) {
      dispatch(productActions.setNoOrderFees());
      return;
    }

    dispatch(
      productActions.getOrderFees(
        orderFeesReqBody(
          selectedProducts,
          productDonation,
          formValues,
          campaignId,
        ),
      ),
    );
  };

  const onRateSelect = e => {
    const id = Number(e.target.value);
    if (Array.isArray(rateQuotes)) {
      const active = rateQuotes.find(({ id: _id }) => id === _id);
      if (active) {
        setQuoteId(id);

        const { shipping, serviceCode, serviceName } = active;
        setServiceName(serviceName);
        dispatch(productActions.setShipping(shipping));
        form.change("shippingServiceCode", serviceCode);
      }
    }
  };

  const onLiveRateConfirm = async () => {
    // if no tax (no Nexus OR doesn't use TaxJar)
    // close rate selector & show fees
    if (!needsTaxFetch) {
      setShowShippingRates(false);
    } else {
      setFetchingLiveRateTax(true);
      const gotTax = await dispatch(
        productActions.getOrderFees(
          orderFeesReqBody(
            selectedProducts,
            productDonation,
            formValues,
            campaignId,
            shipping,
          ),
          true,
        ),
      );
      if (gotTax) {
        setShowShippingRates(false);
        setFetchingLiveRateTax(false);
      } else {
        // TODO: figure out what to do is this case
      }
    }
  };

  const placeOrder = () => {
    if (!validateForm(form)) return;
    form.submit();
  };

  const ShippingType = () => (
    <div className={classes.shipType}>
      {isShipToSchool ? (
        <SchoolIcon className={classes.icon} />
      ) : (
        <HomeIcon className={classes.icon} />
      )}
      {isShipToSchool
        ? `Shipping to ${ship_to_school_label}`
        : "Shipping to home"}
    </div>
  );

  const PaymentBtn = () => (
    <Button
      variant="contained"
      color="primary"
      fullWidth
      className={classes.button}
      classes={{ disabled: classes.disabledBtn }}
      disabled={disableSubmit}
      onClick={
        needsPayment ? () => validateFormAndOpenPayment(form) : placeOrder
      }
    >
      {submitting ? (
        <Fragment>
          <CircularProgress size={40} className={classes.spinner} />
          Submitting...
        </Fragment>
      ) : (
        `${
          disableRetry
            ? "Please refresh your page"
            : displayingApplePay
            ? "Checkout with credit card"
            : needsPayment
            ? "Enter payment info"
            : "Place order"
        }`
      )}
    </Button>
  );

  const Row = ({ label, amount, isNegative = false, alwaysOn = false }) => {
    if (!amount || (!alwaysOn && !showFees)) return <></>;
    return (
      <div className={classes.row}>
        <div className={classes.label}>{label}</div>
        <div className={classes.amount}>{formatMoney(amount, isNegative)}</div>
      </div>
    );
  };

  if (emptyCart) return <></>;
  // donation only
  if (orderItemCount < 1) {
    return (
      <div className={classes.container}>
        <Row label="Donation" amount={productDonation} alwaysOn />
        <Row
          label={processing_fee_title ? processing_fee_title : "Processing fee"}
          amount={productDonationProcessingFee}
          alwaysOn
        />
        {productDonationProcessingFee > 0 && (
          <div className={classes.totalRow}>
            <div className={classes.total}>Total</div>
            <div className={classes.totalAmount}>{formatMoney(orderTotal)}</div>
          </div>
        )}
        {!forConfirmation && <PaymentBtn />}
      </div>
    );
  }
  return (
    <div className={classes.container}>
      <Row label="Items subtotal" amount={orderItemsTotal} alwaysOn />
      <Row label="Donation" amount={productDonation} alwaysOn />
      {showFees && (
        <div className={classes.row}>
          <div>
            <div className={classes.label}>
              Shipping{" "}
              {serviceName && (
                <IconButton onClick={() => setShowShippingRates(true)}>
                  <CreateIcon fontSize="small" color="inherit" />
                </IconButton>
              )}
            </div>
            {serviceName && (
              <div className={classes.subLabel}>{serviceName}</div>
            )}
          </div>

          <div className={classes.amount}>{formatMoney(shipping)}</div>
        </div>
      )}
      <Row
        label={custom_fee_label ? custom_fee_label : "Additional fee"}
        amount={orderCustomFee}
      />
      <Row label="Online fee" amount={orderOnlineFee} />
      <Row label="Tax" amount={tax} />
      <Row label="Gift Card(s)" amount={giftCardsValue} isNegative />
      {<ShippingType />}

      {showFees && (
        <div className={classes.totalRow}>
          <div className={classes.total}>Total</div>
          <div className={classes.totalAmount}>{formatMoney(orderTotal)}</div>
        </div>
      )}

      {showShippingRates && !forConfirmation && (
        <div>
          <div className={classes.soHeader}>Select a shipping option</div>
          {loadingOrderFees && (
            <div className={classes.fetchingRatesContainer}>
              <CircularProgress size={30} />
              <div className={classes.fetchingTxt}>Fetching quotes</div>
            </div>
          )}
          {!loadingOrderFees && Array.isArray(rateQuotes) && rateQuotes.length && (
            <div className={classes.rates}>
              <RadioGroup value={quoteId} onChange={onRateSelect}>
                {rateQuotes.map((quote, index) => {
                  const { id, shipping, serviceName } = quote;
                  return (
                    <div className={classes.rateRow} key={index}>
                      <FormControlLabel
                        control={<Radio value={id} />}
                        className={classes.rateLabel}
                        label={serviceName}
                      />
                      <div className={classes.rateAmount}>
                        {formatMoney(shipping)}
                      </div>
                    </div>
                  );
                })}
              </RadioGroup>
            </div>
          )}
          <Button
            variant="contained"
            color="primary"
            fullWidth
            className={classes.button}
            classes={{ disabled: classes.disabledBtn }}
            onClick={onLiveRateConfirm}
            disabled={!quoteId || fetchingLiveRateTax}
          >
            Review order
            {fetchingLiveRateTax && (
              <CircularProgress
                color="secondary"
                size={30}
                className={classes.spinner}
              />
            )}
          </Button>
        </div>
      )}

      {!showShippingRates &&
        !forConfirmation &&
        orderItemCount > 0 &&
        !showFees && (
          <Button
            variant="contained"
            color="primary"
            fullWidth
            className={classes.button}
            classes={{ disabled: classes.disabledBtn }}
            onClick={getFees}
            disabled={!isActive || loadingOrderFees}
          >
            {`Calculate shipping & fees`}
            {loadingOrderFees && (
              <CircularProgress
                color="secondary"
                size={30}
                className={classes.spinner}
              />
            )}
          </Button>
        )}

      {!forConfirmation && showFees && <PaymentBtn />}
      {!forConfirmation && showFees && (
        <ApplePay
          submitting={submitting}
          disableRetry={disableRetry}
          needsPayment={needsPayment}
          setDisplayingApplePay={setDisplayingApplePay}
        />
      )}
    </div>
  );
}

export const OrderSummaryAndActions = connect(state => {
  const {
    donation: { paymentType, donationSuccess, disableRetry },
    product,
    campaign: {
      campaignId,
      canadianCampaign,
      isActive,
      ship_to_school_label,
      processing_fee_title,
    },
  } = state;
  return {
    campaignId,
    productInfo: product,
    paymentType,
    disableRetry,
    canadianCampaign,
    donationSuccess,
    isActive,
    ship_to_school_label,
    processing_fee_title,
  };
})(_OrderSummaryAndActions);

function orderFeesReqBody(
  selectedProducts,
  productDonation,
  formValues,
  campaignId,
  liveRateShipping,
) {
  const {
    shipping_same_as_billing: sameAsBilling,
    ship_to_school,
    address: billingAddress,
    city: billingCity,
    state: billingState,
    zip: billingZip,
    shipping_address,
    shipping_city,
    shipping_state,
    shipping_zip,
    pmt_type,
    giftCards,
  } = formValues;
  let shipping_ounces = 0;
  let shipping_item_count = 0;
  let hasShippingItems = false;
  let total = productDonation ? Number(productDonation) : 0;

  const selected_products = Object.keys(selectedProducts).map(product_id => {
    const {
      qty,
      product: { weight_ounces, product_price, product_type, no_shipping_fees },
    } = selectedProducts[product_id];
    total = (Number(total) + Number(qty * Number(product_price))).toFixed(2);
    if (product_type === productTypes.PHYSICAL && !no_shipping_fees) {
      hasShippingItems = true;
      shipping_ounces = shipping_ounces + weight_ounces * qty;
      shipping_item_count = shipping_item_count + qty;
    }
    return { product_id: Number(product_id), qty };
  });

  return {
    campaign_id: campaignId,
    selected_products,
    shipping_ounces,
    shipping_item_count,
    hasShippingItems,
    total,
    address: sameAsBilling ? billingAddress : shipping_address,
    city: sameAsBilling ? billingCity : shipping_city,
    state: sameAsBilling ? billingState : shipping_state,
    zip: sameAsBilling ? billingZip : shipping_zip,
    ship_to_school,
    address_validation_only: pmt_type !== "cc" ? true : null,
    gift_cards: convertGiftCardObjArrToCodeStrArr(giftCards),
    liveRateShippingAmount: liveRateShipping ? `${liveRateShipping}` : null,
    tax_only: Boolean(liveRateShipping),
  };
}

const styles = makeStyles(theme => ({
  container: {
    width: 360,
    maxWidth: "100vw",
    [theme.breakpoints.down("md")]: {
      width: 732,
      padding: "40px 16px 24px 16px",
    },
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 24,
  },
  label: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.25,
    lineHeight: "22px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      letterSpacing: 0.2,
      lineHeight: "17px",
    },
  },
  subLabel: {
    fontSize: 14,
    letterSpacing: 0.17,
    paddingTop: 8,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 4,
    },
  },
  amount: {
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: 0.25,
    lineHeight: "22px",
  },
  orderError: {
    color: "red",
  },
  shipType: {
    height: 24,
    width: "fit-content",
    padding: "0 8px",
    borderRadius: 14,
    backgroundColor: hexToRGBA(theme.palette.primary.main, 0.25),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.17,
    color: theme.palette.primary.main,
    marginBottom: 32,
  },
  icon: {
    marginRight: 4,
    fontSize: 16,
  },
  totalRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 32,
  },
  total: {
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: 0.25,
  },
  totalAmount: {
    fontSize: 32,
    fontWeight: 600,
    letterSpacing: 0.4,
    color: theme.palette.primary.main,
  },
  button: {
    height: 56,
    borderRadius: 28,
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: 0.25,
    position: "relative",
  },
  disabledBtn: {
    opacity: "0.5 !important",
    color: `${theme.palette.primary.contrastText} !important`,
    backgroundColor: `${theme.palette.primary.main} !important`,
  },
  spinner: { position: "absolute" },
  soHeader: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.25,
    marginBottom: 18,
  },
  fetchingRatesContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: 156,
  },
  fetchingTxt: {
    fontSize: 14,
    letterSpacing: 0.17,
    marginTop: 20,
  },
  rates: {
    marginBottom: 16,
  },
  rateRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
  },
  rateLabel: {
    fontSize: 20,
    letterSpacing: 0.25,
  },
  rateAmount: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.25,
  },
}));
