// As we get each companies info we will update this file
// TODO: down the line we may change to persist this in the DB, but for now it's hardcoded because we don't know how we want it to look yet

const host = window.location.host;

const faviconIds = {
  "www.instaraise.com": 1,
  "instaraise.com": 1,
  "www.directshipfundraiser.com": 1,
  "directshipfundraiser.com": 1,
  "www.givvr.live": 3,
  "givvr.live": 3,
  "givvronline.com": 3,
  "givvrlive.com": 3,
  "givvrcharity.com": 3,
  "onlineorders.mrsfieldsfundraising.com": 1,
  "onlyfundraising.com": 1,
  "canadafundraising.com": 1,
  "onlineorders.brandnamefundraising.com": 1,
  "online.ultimatefundraising.ca": 16,
  "online.kidsarefirst.com": 1,
  "needmoolah.com": 1,
  "www.needmoolah.com": 1,
  "shop.gwrcfundraising.com": 19,
  "www.shop.gwrcfundraising.com": 19,
  "shop.1cookie.com": 1,
  "bigkahunaprime.net": 21,
  "www.bigkahunaprime.net": 21,
  "myfundraisingshop.com": 28,
  "www.myfundraisingshop.com": 28,
  "donate.schoolathon.org": 30,
  "www.donate.schoolathon.org": 30,
  /// STAGING ///
  "jm.bitbean.dev": 1,
  /// DEV ///
  "localhost:3000": 1,
};
let faviconPrefixId = 1;
if (faviconIds[host]) faviconPrefixId = faviconIds[host];

const homeLogoIds = {
  "www.instaraise.com": 1,
  "instaraise.com": 1,
  "www.directshipfundraiser.com": 2,
  "directshipfundraiser.com": 2,
  "www.givvr.live": 3,
  "givvr.live": 3,
  "givvronline.com": 3,
  "givvrlive.com": 3,
  "givvrcharity.com": 3,
  "onlineorders.mrsfieldsfundraising.com": 4,
  "onlyfundraising.com": 7,
  "canadafundraising.com": 10,
  "onlineorders.brandnamefundraising.com": 11,
  "online.ultimatefundraising.ca": 16,
  "online.kidsarefirst.com": 17,
  "needmoolah.com": 18,
  "www.needmoolah.com": 18,
  "shop.gwrcfundraising.com": 19,
  "www.shop.gwrcfundraising.com": 19,
  "shop.1cookie.com": 20,
  "bigkahunaprime.net": 21,
  "www.bigkahunaprime.net": 21,
  "myfundraisingshop.com": 28,
  "www.myfundraisingshop.com": 28,
  "donate.schoolathon.org": 30,
  "www.donate.schoolathon.org": 30,
  /// STAGING ///
  "jm.bitbean.dev": 1,
  /// DEV ///
  "localhost:3000": 1,
};
let homeLogoPrefixId = 1;
if (homeLogoIds[host]) homeLogoPrefixId = homeLogoIds[host];

const hostTitles = {
  "www.instaraise.com": "InstaRaise Fundraising",
  "instaraise.com": "InstaRaise Fundraising",
  "www.directshipfundraiser.com": "Hibernate",
  "directshipfundraiser.com": "Hibernate",
  "www.givvr.live": "GiVVR",
  "givvr.live": "GiVVR",
  "givvronline.com": "GiVVR",
  "givvrlive.com": "GiVVR",
  "givvrcharity.com": "GiVVR",
  "onlineorders.mrsfieldsfundraising.com": "Fundraising Manager",
  "onlyfundraising.com": "Fundraising.com",
  "canadafundraising.com": "QSP Canada",
  "onlineorders.brandnamefundraising.com": "Simply Goodness",
  "online.ultimatefundraising.ca": "Ultimate Fundraising",
  "online.kidsarefirst.com": "Kids Are First Fundraising",
  "needmoolah.com": "Moolah",
  "www.needmoolah.com": "Moolah",
  "shop.gwrcfundraising.com": "Great Western Reserve",
  "www.shop.gwrcfundraising.com": "Great Western Reserve",
  "shop.1cookie.com": "Neighbors Cookies",
  "bigkahunaprime.net": "Big Kahuna Fundraising",
  "www.bigkahunaprime.net": "Big Kahuna Fundraising",
  "myfundraisingshop.com": "Diamond Fundraising",
  "www.myfundraisingshop.com": "Diamond Fundraising",
  "donate.schoolathon.org": "Schoolathon",
  "www.donate.schoolathon.org": "Schoolathon",
  /// STAGING ///
  "jm.bitbean.dev": "Instaraise Staging",
  /// DEV ///
  "localhost:3000": "Instaraise Dev",
};
let hostTitle = "Instaraise";
if (hostTitles[host]) hostTitle = hostTitles[host];

const homeImageIds = {
  "www.instaraise.com": 1,
  "instaraise.com": 1,
  "www.directshipfundraiser.com": 1,
  "directshipfundraiser.com": 1,
  "www.givvr.live": 3,
  "givvr.live": 3,
  "givvronline.com": 3,
  "givvrlive.com": 3,
  "givvrcharity.com": 3,
  "onlineorders.mrsfieldsfundraising.com": 1,
  "onlyfundraising.com": 1,
  "canadafundraising.com": 1,
  "onlineorders.brandnamefundraising.com": 1,
  "online.ultimatefundraising.ca": 1,
  "online.kidsarefirst.com": 1,
  "needmoolah.com": 1,
  "www.needmoolah.com": 1,
  "shop.gwrcfundraising.com": 1,
  "www.shop.gwrcfundraising.com": 1,
  "shop.1cookie.com": 1,
  "bigkahunaprime.net": 21,
  "www.bigkahunaprime.net": 21,
  "myfundraisingshop.com": 28,
  "www.myfundraisingshop.com": 28,
  "donate.schoolathon.org": 30,
  "www.donate.schoolathon.org": 30,
  /// STAGING ///
  "jm.bitbean.dev": 1,
  /// DEV ///
  "localhost:3000": 1,
};
let homeImagePrefixId = 1;
if (homeImageIds[host]) homeImagePrefixId = homeImageIds[host];

const homeBackgroundColors = {
  "www.instaraise.com": "#0499C8",
  "instaraise.com": "#0499C8",
  "www.directshipfundraiser.com": "#0499C8",
  "directshipfundraiser.com": "#0499C8",
  "www.givvr.live": "#85BB65",
  "givvr.live": "#85BB65",
  "givvronline.com": "#85BB65",
  "givvrlive.com": "#85BB65",
  "givvrcharity.com": "#85BB65",
  "onlineorders.mrsfieldsfundraising.com": "#0499C8",
  "onlyfundraising.com": "#0499C8",
  "canadafundraising.com": "#0499C8",
  "onlineorders.brandnamefundraising.com": "#0499C8",
  "online.ultimatefundraising.ca": "#0499C8",
  "online.kidsarefirst.com": "#0499C8",
  "needmoolah.com": "#0499C8",
  "www.needmoolah.com": "#0499C8",
  "shop.gwrcfundraising.com": "#0499C8",
  "www.shop.gwrcfundraising.com": "#0499C8",
  "shop.1cookie.com": "#0499C8",
  "bigkahunaprime.net": "#3791CF",
  "www.bigkahunaprime.net": "#3791CF",
  "myfundraisingshop.com": "#00954A",
  "www.myfundraisingshop.com": "#00954A",
  "donate.schoolathon.org": "#FF328C",
  "www.donate.schoolathon.org": "#FF328C",
  /// STAGING ///
  "jm.bitbean.dev": "#0499C8",
  /// DEV ///
  "localhost:3000": "#0499C8",
};
let homeBackgroundColor = "#0499C8";
if (homeBackgroundColors[host]) {
  homeBackgroundColor = homeBackgroundColors[host];
}

export {
  faviconPrefixId,
  homeLogoPrefixId,
  hostTitle,
  homeImagePrefixId,
  homeBackgroundColor,
};
